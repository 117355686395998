import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from "./components/navbar/Navbar"
import Home from "./pages/home/Home"
import About from "./pages/about/About"
import Products from "./pages/products/Products"
import Contact from "./pages/contact/Contact"
import Footer from "./components/footer/Footer"
import ScrollToTop from './components/scrollbtn/ScrollToTop';
import {useLayoutEffect} from 'react';


function App() {
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <Navbar />
            <Routes>
              <Route index element={<Home />} />
              <Route path='/about' element={<About/>} />
              <Route path='/products' element={<Products/>} />
              <Route path='/contact' element={<Contact/>} />
            </Routes>
          <Footer />
        </Wrapper>
      </BrowserRouter>
      <ScrollToTop />
    </>
  );
}

export default App;
