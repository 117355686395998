import "./form.css"
import { FaEnvelope, FaWhatsapp  } from "react-icons/fa";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function Form() {
    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_5tfm5ao', 'template_7aq5uxb', form.current, {
        publicKey: 'eNbgcKuc1zW4uufyJ',
      })
      e.target.reset();
  };

    return(
        <>
            <section className="contact-form">
                <div className="header">
                    <h3>تواصل معنا بكل حرية</h3>
                </div>
                <div className="container">
                    <div className="content">
                        <div className="contact-info">
                            <div className="contact-card">
                                <FaEnvelope size={25} color="#c29f55" />
                                <h3>البريد الالكتروني</h3>
                                <p>albeetalraky@gmail.com</p>
                            </div>

                            <div className="contact-card">
                                <FaWhatsapp size={29} color="#c29f55" />
                                <h3>واتس اب</h3>
                                <p>+9665 3438 6181</p>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <form ref={form} onSubmit={sendEmail} className="contact-form-form">
                            <div className="contact-form-div">
                                <input type="text" className="input" name="name" placeholder="الاسم بالكامل" />
                                <input type="email" className="input" name="email" placeholder="البريد الالكتروني" />
                                <input type="text" className="input" name="address" placeholder="العنوان" />
                                <textarea id="" cols="30" rows="10" name="project" className="input contact-form-area" placeholder="الرسالة"></textarea>
                            </div>
                            <button className="btn">إرسال</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}