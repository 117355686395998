import "./aboutsec2.css"
import Sec1 from "../../../assets/door1.webp"
import Sec2 from "../../../assets/door2.webp"
import Sec3 from "../../../assets/door3.webp"
import Sec4 from "../../../assets/door4.jpg"

export default function Aboutsec2() {
    return(
        <>
            <section className="aboutsec2">
                <div className="header-title" >
                    <h2 className="title">متميزون في خشب التك الامريكي المجفف للأبواب الخارجية</h2>
                </div>

                <div className="content">
                    <div className="image">
                        <img src={Sec1} alt="" />
                    </div>
                    <div className="image">
                        <img src={Sec2} alt="" />
                    </div>
                    <div className="image">
                        <img src={Sec3} alt="" />
                    </div>
                    <div className="image">
                        <img src={Sec4} alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}