import { FaTruck, FaCheck, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { FaBoxArchive, FaHandHoldingDollar, FaLocationDot, FaGear  } from "react-icons/fa6";
import Work1 from "./assets/work1.webp"
import Work2 from "./assets/hrh.webp"
import Work3 from "./assets/work3.webp"
import Work4 from "./assets/work4.webp"
import Work5 from "./assets/dwd.webp"
import Work6 from "./assets/hrth.webp"
import Work7 from "./assets/tv.webp"
import Work8 from "./assets/part.webp"
import Work9 from "./assets/6fa56c61d5aab7f947e00a1cb028e370.webp"
import Work10 from "./assets/work10.webp"
import Exdoor1 from "./assets/exdoor1.webp"
import Exdoor2 from "./assets/exdoor2.webp"
import Exdoor3 from "./assets/exdoor3.webp"
import Exdoor4 from "./assets/exdoor4.webp"
import Pro1 from "./assets/pro1.webp"
import Pro2 from "./assets/pro2.webp"
import Pro3 from "./assets/pro3.webp"
import Pro4 from "./assets/pro4.webp"
import Pro5 from "./assets/pro5.webp"
import Pro6 from "./assets/pro6.webp"
import Pro7 from "./assets/pro7.webp"
import Pro8 from "./assets/pro8.webp"
import Pro9 from "./assets/pro9.webp"
import Pro10 from "./assets/pro10.webp"
import Pro11 from "./assets/pro11.webp"
import Pro12 from "./assets/pro12.webp"
import Pro13 from "./assets/pro13.webp"
import Pro14 from "./assets/pro14.webp"
import Pro15 from "./assets/pro15.webp"
import Pro16 from "./assets/pro16.webp"
import Pro17 from "./assets/pro17.webp"
import Pro18 from "./assets/pro18.webp"
import Pro19 from "./assets/pro19.webp"
import Pro20 from "./assets/pro20.jpg"
import Pro21 from "./assets/pro21.webp"
import Pro22 from "./assets/pro22.webp"
import Pro23 from "./assets/pro23.webp"
import Pro24 from "./assets/pro24.webp"
import Pro25 from "./assets/pro25.webp"
import Pro26 from "./assets/pro26.webp"
import Pro27 from "./assets/pro27.webp"
import Pro28 from "./assets/pro28.webp"
import Pro29 from "./assets/pro29.webp"
import Pro30 from "./assets/pro30.webp"
import Pro31 from "./assets/pro31.webp"
import Pro32 from "./assets/pro32.webp"
import Pro33 from "./assets/pro33.jpg"
import Pro34 from "./assets/pro34.webp"
import Pro35 from "./assets/pro35.webp"
import Pro36 from "./assets/pro36.webp"
import Pro37 from "./assets/pro37.webp"
import Pro38 from "./assets/pro38.webp"
import Pro39 from "./assets/pro39.webp"
import Pro40 from "./assets/pro40.webp"
import Pro41 from "./assets/pro41.webp"
import Pro42 from "./assets/pro42.webp"
import Pro43 from "./assets/pro43.webp"
import Pro44 from "./assets/pro44.webp"
import Pro45 from "./assets/pro45.jpg"
import Pro46 from "./assets/pro46.webp"
import Pro47 from "./assets/pro47.webp"
import Pro48 from "./assets/pro48.webp"

export const ServicesCards = [
    {
        id: 1, 
        icon: <FaTruck />,
        title: "شحن مجاني",
    },
    {
        id: 2, 
        icon: <FaBoxArchive />,
        title: "التعبئة و التغليف",
    },
    {
        id: 3, 
        icon: <FaCheck  />,
        title: "أفضل الخامات",
    },
    {
        id: 4, 
        icon: <FaHandHoldingDollar />,
        title: "أسعار منافسة",
    },
    {
        id: 5,
        icon: <FaGear />,
        title: "دقة التصنيع",
    }
]


export const Workdata = [
    {
        id: 1,
        img: Work1,
        title: "أبواب داخلية",
        para: "الابواب الخشبية أفضل خيار طالما كنت تبحث عن الرقي والأناقة",
    },
    {
        id: 2,
        img: Work2,
        title: "غرف نوم",
        para: "أطقم غرف نوم كاملة مفرد أو مزدوج لفردين بتصميم راقي وجودة عاليه",
    },
    {
        id: 3,
        img: Work3,
        title: "أبواب خارجية",
        para: "الابواب الخشبية أفضل خيار طالما كنت تبحث عن الرقي والأناقة",
    },
    {
        id: 4,
        img: Work4,
        title: "دواليب ملابس",
        para: "احصل علي افضل دولاب من ضمن مجموعة كبيرة من الاشكال الرائعة",
    },
    {
        id: 5,
        img: Work5,
        title: "خزائن مطبخ",
        para: " الخشب هو الخيار الأفضل لتجديد مطبخك بأفضل طراز",
    },
    {
        id: 6,
        img: Work6,
        title: "كوفي كورنر",
        para: "الخشب هو خيار كلاسيكي وشائع لركن القهوة ",
    },
    {
        id: 7,
        img: Work7,
        title: "وحدات تليفزيون",
        para: "وحدات تليفزيون بتصميمات عصرية تناسب مختلف الأذواق",
    },
    {
        id: 8,
        img: Work8,
        title: "فواصل خشب",
        para: "بارتشن الديكور تعطى شكلًا مميزًا و طابع راقي للمكان",
    },
    {
        id: 9,
        img: Work9,
        title: "كنب",
        para: "تشكيلة مختلفة من الكنب التي تعطي راحة و طابع راقي"
    },
    {
        id: 10,
        img: Work10,
        title: "أبواب سحاب",
        para: "تعطيك ابواب السحاب أناقة و مساحات رؤية واسعة ",
    },
]


export const Workinghours = [
    {
        id: 1,
        icon: <FaWhatsapp />,
        title: "واتس اب",
        para: "+9665 3438 6181"
    },
    {
        id: 2,
        icon: <FaLocationDot />,
        title: "العنوان",
        para: "جدة - مدينة البناء",
    },
    {
        id: 3,
        icon: <FaEnvelope />,
        title: "البريد الالكتروني",
        para: "albeetalraky@gmail.com",
    },
]



export const Testidata = [
    {
        id: 1,
        name: "احمد محمد",
        text: "من أكثر الاماكن رقي في التعامل و الجودة ممتازة جدا و التسليم في الميعاد",
    },
    {
        id: 2,
        name: "مصطفي احمد",
        text: "كان التنفيذ تماما كما طلب وبجودة رائعة والتسليم بالوقت المحدد وبكل احترافية",
    },
    {
        id: 3,
        name: "هاني صادق",
        text: "كل الشكر على المصداقية والعمل المتقن والتعامل الراقي",
    },
    {
        id: 4,
        name: "نهال فتحي",
        text: "عمل اكثر من رائع و جودة رائعة و الخشب ممتاز و التسليم في الموعد",
    },
]

export const Exdoor = [
    {
        id: 1,
        img: Exdoor1,
    },
    {
        id: 2,
        img: Exdoor2,
    },
    {
        id: 3,
        img: Exdoor3,
    },
    {
        id: 4,
        img: Exdoor4,
    },
]

export const Prodata = [
    {
        id: 1,
        img: Pro1,
        category: "أبواب خارجية",
    },
    {
        id: 2,
        img: Pro2,
        category: "مطابخ",
    },
    {
        id: 3,
        img: Pro3,
        category: "أبواب داخلية",
    },
    {
        id: 4,
        img: Pro4,
        category: "غرف نوم",
    },
    {
        id: 5,
        img: Pro5,
        category: "مطابخ",
    },
    {
        id: 6,
        img: Pro6,
        category: "كنب",
    },
    {
        id: 7,
        img: Pro7,
        category: "كوفي كورنر",
    },
    {
        id: 8,
        img: Pro8,
        category: "وحدات تليفزيون",
    },
    {
        id: 9,
        img: Pro9,
        category: "خزائن ملابس",
    },
    {
        id: 10,
        img: Pro10,
        category: "أبواب داخلية",
    },
    {
        id: 11,
        img: Pro11,
        category: "غرف نوم",
    },
    {
        id: 12,
        img: Pro12,
        category: "أبواب خارجية",
    },
    {
        id: 13,
        img: Pro13,
        category: "فواصل خشب",
    },
    {
        id: 14,
        img: Pro14,
        category: "خزائن ملابس",
    },
    {
        id: 15,
        img: Pro15,
        category: "غرف نوم",
    },
    {
        id: 16,
        img: Pro16,
        category: "أبواب خارجية",
    },
    {
        id: 17,
        img: Pro17,
        category: "وحدات تليفزيون",
    },
    {
        id: 18,
        img: Pro18,
        category: "كوفي كورنر",
    },
    {
        id: 19,
        img: Pro19,
        category: "فواصل خشب",
    },
    {
        id: 20,
        img: Pro20,
        category: "كنب",
    },
    {
        id: 21,
        img: Pro21,
        category: "خزائن ملابس",
    },
    {
        id: 22,
        img: Pro22,
        category: "مطابخ",
    },
    {
        id: 23,
        img: Pro23,
        category: "أبواب داخلية",
    },
    {
        id: 24,
        img: Pro24,
        category: "فواصل خشب",
    },
    {
        id: 25,
        img: Pro25,
        category: "كنب",
    },
    {
        id: 26,
        img: Pro26,
        category: "مطابخ",
    },
    {
        id: 27,
        img: Pro27,
        category: "أبواب خارجية",
    },
    {
        id: 28,
        img: Pro28,
        category: "غرف نوم",
    },
    {
        id: 29,
        img: Pro29,
        category: "كوفي كورنر",
    },
    {
        id: 30,
        img: Pro30,
        category: "خزائن ملابس",
    },
    {
        id: 31,
        img: Pro31,
        category: "أبواب خارجية",
    },
    {
        id: 32,
        img: Pro32,
        category: "أبواب داخلية",
    },
    {
        id: 33,
        img: Pro33,
        category: "وحدات تليفزيون",
    },
    {
        id: 34,
        img: Pro34,
        category: "فواصل خشب",
    },
    {
        id: 35,
        img: Pro35,
        category: "أبواب داخلية",
    },
    {
        id: 36,
        img: Pro36,
        category: "خزائن ملابس",
    },
    {
        id: 37,
        img: Pro37,
        category: "غرف نوم",
    },
    {
        id: 38,
        img: Pro38,
        category: "مطابخ",
    },
    {
        id: 39,
        img: Pro39,
        category: "خزائن ملابس",
    },
    {
        id: 40,
        img: Pro40,
        category: "خزائن ملابس",
    },
    {
        id: 41,
        img: Pro41,
        category: "كنب",
    },
    {
        id: 42,
        img: Pro42,
        category: "خزائن ملابس",
    },
    {
        id: 43,
        img: Pro43,
        category: "أبواب سحاب",
    },
    {
        id: 44,
        img: Pro44,
        category: "أبواب سحاب",
    },
    {
        id: 45,
        img: Pro45,
        category: "خزائن ملابس",
    },
    {
        id: 46,
        img: Pro46,
        category: "أبواب سحاب",
    },
    {
        id: 47,
        img: Pro47,
        category: "أبواب سحاب",
    },
    {
        id: 48,
        img: Pro48,
        category: "كنب",
    },
]

export const Categories = [
    {
        name: "الكل"
    },
    {
        name: "أبواب سحاب"
    },
    {
        name: "فواصل خشب"
    },
    {
        name: "كوفي كورنر"
    },
    {
        name: "وحدات تليفزيون"
    },
    {
        name: "خزائن ملابس"
    },
    {
        name: "مطابخ"
    },
    {
        name: "كنب"
    },
    {
        name: "غرف نوم"
    },
    {
        name: "أبواب داخلية"
    },
    {
        name: "أبواب خارجية"
    },
    
    
]