import "./aboutsec3.css"
import Dressing1 from "../../../assets/dressing1.jpg"
import Dressing2 from "../../../assets/dressing2.webp"
import Dressing3 from "../../../assets/dressing3.webp"
import Dressing4 from "../../../assets/dressing4.webp"

export default function Aboutsec3() {
    return(
        <>
            <section className="aboutsec3">
                <div className="header-title" >
                    <h2 className="title">تفصيل دواليب خشب تشبورد الالماني و خشب تايلاندي و خشب طبيعي</h2>
                </div>

                <div className="content">
                    <div className="image">
                        <img src={Dressing1} alt="" />
                    </div>
                    <div className="image">
                        <img src={Dressing2} alt="" />
                    </div>
                    <div className="image">
                        <img src={Dressing3} alt="" />
                    </div>
                    <div className="image">
                        <img src={Dressing4} alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}