import "./ourservices.css"
import { ServicesCards } from "../../../data"
// import AOS from 'aos';
import 'aos/dist/aos.css';
// import { useEffect } from "react";

export default function Ourservices() {
    // useEffect(() => {
    //     AOS.init({
    //         duration: 1500,
    //     });
    // }, [])
    return(
        <>
            <section className="ourservices">
                <div className="header-title" >
                    <h1 className="main-title">خدماتنا المميزة</h1>
                    <h2 className="title">افرش بيتك كله من مكان واحدة</h2>
                </div>

                <div className="services" >
                    {ServicesCards.map(({id, icon, title}) => {
                        return(
                            <div className="service" key={id}>
                                <div className="icon">{icon}</div>
                                <h3 className="title">{title}</h3>
                            </div>
                        )
                    })}
                </div>
            </section>

        </>
    )
}