import "./testi.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay'
import 'swiper/css/pagination';
import { Testidata } from "../../../data"
import { FaStar, FaQuoteRight } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";



export default function Testi() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, [])
    return(
        <>
            <section className="testi">
                <div className="header-title" >
                        <h1 className="main-title">آراء العملاء</h1>
                        <h2 className="title">تميز بأثاث فريد ومميز</h2>
                </div>
                
                <div className="content" > 
                    <Swiper
                        spaceBetween={35}
                        slidesPerView={3}
                        loop={true}
                        autoplay={{
                          delay: 3800,
                          disableOnInteraction: false,
                        }}
                        breakpoints={{
                            "@0.00": {
                            slidesPerView: 1,
                          },
                          "@0.75": {
                            slidesPerView: 2,
                          },
                          "@1.00": {
                              slidesPerView: 3,
                          }
                        }}
                        pagination={{
                          el: ".swiper-pagination",
                          clickable: true,
                        }}
                        modules={[Autoplay, Pagination]}
                        >
                            {Testidata.map(({id, name, text}) =>{
                                return(
                                    <SwiperSlide>
                                        <div className="card" key={id}>
                                            <h2>{name}</h2>
                                            <p>{text}</p>
                                            <div className="icons">
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                            </div>
                                            <div className="quote">
                                                <FaQuoteRight />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                            
                            <div class="swiper-pagination"></div>
                    </Swiper>
                </div>  
            </section>
        </>
    )
}