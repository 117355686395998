import "./aboutsec.css"
import Img1 from "../../../assets/sec1.webp"
import Img2 from "../../../assets/sec2.webp"
import Img3 from "../../../assets/sec3.webp"
import Img4 from "../../../assets/sec4.webp"

export default function Aboutsec() {
    return(
        <>
            <section className="aboutsec">
                <div className="header-title" >
                    <h2 className="title">تنفيذ جميع الاعمال الخشبية حسب طلب العميل</h2>
                </div>

                <div className="content">
                    <div className="image">
                        <img src={Img1} alt="" />
                    </div>
                    <div className="image">
                        <img src={Img2} alt="" />
                    </div>
                    <div className="image">
                        <img src={Img3} alt="" />
                    </div>
                    <div className="image">
                        <img src={Img4} alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}