import "./aboutraqy.css"
import { FaCheck } from "react-icons/fa6";
import Aboutraq from "../../../assets/Untitkhled.webp"

export default function Aboutraqy() {
    return (
      <>
        <section className="aboutraqy">
          <div className="content">
            <div className="text">
                <h2 className="side-title">عالم البيت الراقي للديكور</h2>

              <p className="title-para">
                اكتشف مفهومًا جديدًا للراحة والأناقة مع تشكيلتنا الحصرية من
                الأثاث التي تناسب كل ذوق و أضف لمسة من الجمال والأناقة إلى حياتك
                اليومية مع أروع المفروشات لدينا
              </p>

              <div className="para">
                    <p>متميزون في خشب التك الامريكي المجفف للأبواب الخارجية</p>
                    <FaCheck size={18} />
              </div>
              <div className="para">
                    <p>تفصيل أبواب و ديكورات خشبية و مطابخ و غرف نوم</p>
                    <FaCheck size={18} />
              </div>
              <div className="para">
                    <p>تفصيل دواليب خشب تشبورد الالماني و خشب تايلاندي و خشب طبيعي</p>
                    <FaCheck size={18} />
              </div>
              <div className="para">
                    <p>تنفيذ جميع الاعمال الخشبية حسب طلب العميل</p>
                    <FaCheck size={18} />
                </div>
            </div>

            <div className="image">
              <img src={Aboutraq} alt="" />
            </div>
          </div>
        </section>
      </>
    );
}