import "./about.css"
import Aboutraqy from "./aboutelraqy/Aboutraqy"
import Aboutsec from "./aboutsec/Aboutsec"
import Aboutsec2 from "./aboutsec2/Aboutsec2"
import Aboutsec3 from "./aboutsec3/Aboutsec3"

export default function About() {
    return(
        <>
            <section className="about" id="about">
                <div className="section-header">
                    <h1>من نحن</h1>
                    <p>لماذا يجب عليك اختيار البيت الراقي للديكور</p>
                </div>
            </section>
            <Aboutraqy />
            <Aboutsec />
            <Aboutsec2 />
            <Aboutsec3 />
        </>
    )
}