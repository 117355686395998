import "./aboutnew.css"
import { FaCheck } from "react-icons/fa6";
import Aboutimg from "../../../assets/1.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Aboutnew() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, [])
    return(
        <>
            <section className="aboutnew" >
            <div className="header-title" >
                <h1 className="main-title">من نحن</h1>
                <h2 className="title">لماذا يجب عليك اختيار البيت الراقي للديكور</h2>
            </div>

            <div className="content" >
                <div className="text">
                    <h2 className="side-title">عالم البيت الراقي للديكور</h2>
                <p className="title-para">
                    اكتشف مفهومًا جديدًا للراحة والأناقة مع تشكيلتنا الحصرية من
                    الأثاث التي تناسب كل ذوق و أضف لمسة من الجمال والأناقة إلى حياتك
                    اليومية مع أروع المفروشات لدينا
                </p>

                <div className="para">
                    <p>تنفيذ جميع الاعمال الخشبية حسب طلب العميل</p>
                    <FaCheck size={18} />
                </div>
                <div className="para">
                    <p>تصاميم متنوعة ومواد عالية الجودة</p>
                    <FaCheck size={18} />
                </div>
                <div className="para">
                    <p>أفضل الأثاث بأسعار مميزة</p>
                    <FaCheck size={18} />
                </div>
                <div className="para">
                    <p>تصاميم فريدة وجودة استثنائية</p>
                    <FaCheck size={18} />
                </div>
                <div className="para">
                    <p>جعلنا التصميم والجودة معيارنا</p>
                    <FaCheck size={18} />
                </div>
                <Link to='/about' className='btn'>عرض المزيد</Link>
                {/* <a href="/about" className="btn">عرض المزيد</a> */}
                </div>

                <div className="image">
                <img src={Aboutimg} alt="" />
                </div>
            </div>
            </section>
        </>
    )
}