import "./prolist.css"
import Card from "./Card"


export default function Prolist() {
    return(
        <>
            <section className="prolist">
                <div className="content">
                    <Card />
                </div>
            </section>
        </>
    )
}