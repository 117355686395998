import { useEffect, useState } from "react";
import "./scrolltotop.css"
import { FaArrowUp } from "react-icons/fa6";

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        if (window.pageYOffset > 100) {
        setIsVisible(true)
        } else {
        setIsVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)

        return () => {
        window.removeEventListener('scroll', toggleVisibility)
        }
    }, [])


    return(
        <>
            <button onClick={scrollToTop} className={isVisible ? 'opacity-100' : 'opacity-0'}>
                <FaArrowUp color="#fff"/>
            </button>
        </>
    )
}