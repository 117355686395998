import "./products.css"
import Prolist from "./prolist/Prolist"

export default function Products() {
    return(
        <>
            <section className="products" id="products">
                <div className="section-header">
                    <h1>اعمالنا</h1>
                    <p>تنفيذ جميع الاعمال الخشبية حسب طلب العميل</p>
                </div>
            </section>
            <Prolist />
        </>
    )
}