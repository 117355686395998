import "./proitem.css"

export default function Proitem({item}) {
    return(
        <>
            <div className="pro-card" key={item.id}>
                <img src={item.img} alt="" />
            </div>
        </>
    )
}