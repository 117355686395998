import "./work.css"
import { Workdata } from "../../../data"
// import { FaArrowLeft } from "react-icons/fa6";
import { FaLongArrowAltLeft } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Work() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, [])
    return(
        <>
            <section className="work">
                <div className="header-title" >
                    <h1 className="main-title">اعمالنا</h1>
                    <h2 className="title">أثاث منزلي فاخر، نقدمه لك بأمان وابتسامة</h2>
                </div>

                <div className="content">
                    <div className="cards" >
                        {Workdata.map(({id, img, title, para}) => {
                            return(
                                <div className="card" key={id}>
                                    <img src={img} alt="" />
                                    <div className="text">
                                        <h2 className="name">{title}</h2>
                                        <p className="para">{para}</p>
                                        <Link to='/products' className='btn2'><FaLongArrowAltLeft size={18} className="arrow" /> اعرف المزيد</Link>
                                        {/* <a href="/products" className="btn2"><FaLongArrowAltLeft size={18} className="arrow" /> اعرف المزيد</a> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="btn-container">
                        <Link to='/products' className='btn'>عرض المزيد</Link>
                        {/* <a href="/products" className="btn">عرض المزيد</a> */}
                    </div>
                </div>
                
            </section>
        </>
    )
}