import "./footer.css"
import Logo from "../../assets/logo.png"
import { FaFacebookF, FaInstagram, FaSnapchatGhost, FaTiktok, FaYoutube } from "react-icons/fa";
import { Workinghours } from "../../data"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Footer(){
    useEffect(() => {
    AOS.init({
        duration: 1500,
    });
    }, [])
    return (
      <>
        <section className="footer" >
          <div className="row">
          <div className="col">
                      <h3>روابط التواصل</h3>

                      <div className="icons">
                          <a href="https://www.facebook.com/people/%D8%B9%D8%A7%D9%84%D9%85-%D8%A7%D9%84%D8%A8%D9%8A%D8%AA-%D8%A7%D9%84%D8%B1%D8%A7%D9%82%D9%8A-%D9%84%D9%84%D8%AF%D9%8A%D9%83%D9%88%D8%B1/61560287061058/?mibextid=LQQJ4d"><FaFacebookF className="social-icon" /></a>
                          <a href="https://www.instagram.com/alm_albet_alraky/?igsh=MXRjeXV5cG4wZGYycw%3D%3D"><FaInstagram className="social-icon" /></a>
                          <a href="https://www.snapchat.com/add/lbyt_lrqy?share_id=xNO7CHEL3IQ&locale=ar-SA"><FaSnapchatGhost className="social-icon" /></a>
                          <a href="https://www.tiktok.com/@albeetalraky12345?_t=8mHzAWTrw3z&_r=1"><FaTiktok className="social-icon" /></a>
                          <a href="https://www.youtube.com/@albeetalrakyadecor"><FaYoutube className="social-icon"/></a>
                      </div>
                    
                </div>

                      <div className="col">
                        <h3>تواصل معنا</h3>

                        <div className="info">
                          {Workinghours.map(({id, icon, para}) =>{
                              return(
                                <div className="info-link" key={id}>
                                  <p>{para}</p>
                                  <div className="icon">{icon}</div>
                                </div>
                              )
                          })}
                        </div>
                      </div>
                
                <div className="col">
                    <h3>روابط سريعة</h3>
                    <ul className="footer-links">
                      {/* <li><a href="/">الرئيسية</a></li>
                      <li><a href="/about">من نحن</a></li>
                      <li><a href="/products">اعمالنا</a></li>
                      <li><a href="/contact">اتصل بنا</a></li> */}
                      <li><Link to='/'>الرئيسية</Link></li>
                      <li><Link to='/about'>من نحن</Link></li>
                      <li><Link to='/products'>اعمالنا</Link></li>
                      <li><Link to='/contact'>اتصل بنا</Link></li>
                    </ul>
                  </div>

                  <div className="col col-3">
                      <img src={Logo} alt="" />
                      <p>
                          اكتشف مفهومًا جديدًا للراحة والأناقة مع تشكيلتنا الحصرية من
                          الأثاث التي تناسب كل ذوق 
                      </p>
                  </div>
                </div>
        </section>
        
      </>
    );
}