import "./home.css"
// import AOS from 'aos';
import 'aos/dist/aos.css';
// import { useEffect } from "react";
import Ourservices from "./our services/Ourservices"
import Aboutnew from "./aboutnew/Aboutnew"
import Work from "./work/Work"
import Testi from "./testimonials/Testi"
import { Link } from "react-router-dom";

export default function Home() {
    // useEffect(() => {
    //     AOS.init({
    //         duration: 1500,
    //     });
    // }, [])
    return(
        <>
           <section className="home" id="home">
                <div className="content">
                    <h1>الابداع يبدأ من هنا</h1>
                    <p> اكتشف مفهومًا جديدًا للراحة والأناقة مع تشكيلتنا الحصرية من الأثاث التي تناسب كل ذوق و أضف لمسة من الجمال والأناقة إلى حياتك اليومية مع أروع المفروشات لدينا</p>
                    <Link to='/contact' className='btn'>تواصل معنا</Link>
                    {/* <a href="/contact" className="btn">تواصل معنا</a> */}
                </div>
            </section> 

            <Aboutnew />
            <Ourservices />
            <Work />
            <Testi />
        </>
    )
}