import "./contact.css"
import Form from "./form/Form"
import Map from "./map/Map"

export default function Contact() {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return(
        <>
            <section className="contact" id="contact" onClick={scrollToTop}>
                <div className="contact-header">
                    <div className="section-header">
                        <h1>تواصل معنا</h1>
                        <p>يسعدنا تواصلكم معنا لمعرفة المزيد من التفاصيل عن خدماتنا</p>
                    </div>
                </div>
            </section>
            <Form />
            <Map />
        </>
    )
}