import "./map.css"

export default function Map() {
    return(
        <>
            <section className="map">
                <div className="content">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.1340249881046!2d39.156737799999995!3d21.6197005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3dbc355af922f%3A0x39bb2b15840f02a2!2z2LnYp9mE2YUg2KfZhNio2YrYqiDYp9mE2LHYp9mC2Yog2YTZhNiv2YrZg9mI2LE!5e0!3m2!1sen!2sus!4v1715268212772!5m2!1sen!2sus" 
                        width="600" 
                        height="350" 
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade">    
                    </iframe>
                </div>
            </section>
        </>
    )
}