import "./navbar.css"
import Logo from "../../assets/logo.png"
import { NavLink } from "react-router-dom"
import { useState } from "react"
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

export default function Navbar() {
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => { setShowMenu(!showMenu)};
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if(window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }
    window.addEventListener('scroll', changeBackground)


    return(
        <>
            <nav className={navbar ? "navbar scroll" : "navbar"}>

                <div className="menu-icons" onClick={toggleMenu}>
                    {showMenu ? <IoClose color="#c29f55" size={25} /> : <FaBars color="#c29f55" size={22} />}
                </div>
                
                <menu>
                    <ul className="nav-links" id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}>
                        <li onClick={toggleMenu} >
                            <NavLink to="/contact" className={({isActive}) => isActive ? "nav-link active-link" : "nav-link"}>
                                <p>اتصل بنا</p>
                            </NavLink>
                        </li>
                        

                        <li onClick={toggleMenu}>
                            <NavLink to="/products" className={({isActive}) => isActive ? "nav-link active-link" : "nav-link"}>
                                <p>اعمالنا</p>
                            </NavLink>
                        </li>

                        <li onClick={toggleMenu}>
                            <NavLink to="/about" className={({isActive}) => isActive ? "nav-link active-link" : "nav-link"}>
                                <p>من نحن</p>
                            </NavLink>
                        </li>

                        <li onClick={toggleMenu}>
                            <NavLink to="/" className={({isActive}) => isActive ? "nav-link active-link" : "nav-link"}>
                                <p>الرئيسية</p>
                            </NavLink>
                        </li>
                    </ul>
                </menu>

                <div className="logo">
                    <img src={Logo} alt="" />
                </div>
            </nav>
        </>
    )
}