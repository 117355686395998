import "./card.css"
import { Categories } from "../../../data"
import { Prodata } from "../../../data"
import Proitem from "./Proitem"
import { useEffect, useState } from "react"

export default function Card() {
    const [item, setItem] = useState({name: "الكل"});
    const [Projects, setProjects] = useState([]);
    const [active, setActive] = useState(0);

    useEffect(() => {
        if(item.name === "الكل") {
            setProjects(Prodata);
        }
        else {
            const newProjects = Prodata.filter((project) => {
                return  project.category === item.name;
            });
            setProjects(newProjects);
        } 
    }, [item])

    const handleClick = (e, index) => {
        setItem({name: e.target.textContent});
        setActive(index);
    }

    return(
        <>
            <div className="filters">
                {Categories.map((item, index) => {
                    return (
                        <span 
                        key={index} 
                        onClick={(e) => {handleClick(e, index)}} 
                        className={`${active === index ? "active-cat" : ""} cat-item`}>{item.name}</span>
                    )
                })}
            </div>
            
            <div className="cards">
                {Projects.map((item) => {
                    return(
                        <Proitem item={item} key={item.id}/>
                    )
                })}
            </div>
        </>
    )
}